import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Faro Desjardins was a student at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{` at the same time that `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart Lauten`}</a>{` was. Faro was born into a middle class family. His Father, `}<a parentName="p" {...{
        "href": "/Kero%20Desjardins",
        "title": "Kero Desjardins"
      }}>{`Kero Desjardins`}</a>{`, was a mildly successful Metallurgist. His Mother, `}<a parentName="p" {...{
        "href": "/Evale%20Desjardins",
        "title": "Evale Desjardins"
      }}>{`Evale Desjardins`}</a>{`, worked as a Cartographer for a Shipping Company.`}</p>
    <p>{`During his early years at school, standardized testing revealed that Faro had `}<a parentName="p" {...{
        "href": "/Affinity",
        "title": "Affinity"
      }}>{`Affinity`}</a>{`. Through hard work, Faro was granted permission to attend `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{` through a scholarship. Through his studies, he developed a specialty in `}<a parentName="p" {...{
        "href": "/Augery",
        "title": "Augery"
      }}>{`Augery`}</a>{`, using Convictions to enhance his physical abilities.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      